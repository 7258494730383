import React from 'react'

const Card4 = ({ icon }) => {
    {/*return (
        <div className='ecomcard'>
             <img src={icon} alt="image" />
            <div className='ecomcontent'>
                <h5 className='font-bold'>E-Commerce</h5>
                <p>Lorem Ipsum is simply dummy text of the </p>
    </div> 
    </div> 
    ) */}
}


export default Card4