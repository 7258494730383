import React from "react";
import { Navbar } from "../Components/Navbar/Navbar";
import { Footer } from "../Components/Footer/Footer";
import { HomeBanner } from "../Components/Home/HomeBanner";

export const Home = () => {
  return (
    <div style={{overflowX:"hidden"}}>
      <Navbar />
      <HomeBanner/>
      <Footer />
    </div>
  );
};
