import React from 'react'
// import Icon from '../../assets/aboutimg/puzzle.webp'
import Line from '../../assets/aboutimg/line.webp'

const Valuecard = ({ icon, title }) => {
    return (
        <div className='purposecard'>
            <div className='card5'>
                <div>
                    {/* <img src={Icon} alt="image" /> */}
                    {/* <i class="fa-solid fa-puzzle-piece"></i> */}
                    {icon}
                </div>
                <h6 className='card-line font-bold'>{title}</h6>
    {/*<p>Upholding honesty and ethical conduct in everything we do, building trust with our stakeholders.</p>*/}
            </div>
        </div>
    )
}

export default Valuecard