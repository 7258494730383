import React, { useEffect } from "react";
import People from "../../assets/aboutimg/four.webp";
import Gear from "../../assets/Service/gear.webp";
import ServicesImg1 from "../../assets/Service/serv-img-1.webp";
import ServicesImg2 from "../../assets/Service/serv-img-2.webp";
import ServicesImg3 from "../../assets/Service/serv-img-3.webp";
import ServicesImg4 from "../../assets/Service/serv-img-4.webp";
import ServicesImg5 from "../../assets/Service/serv-img-5.webp";
import ServicesImg6 from "../../assets/Service/serv-img-6.webp";
import ServicesImg7 from "../../assets/Service/serv-img-7.webp";
import Cl from "../../assets/images/cl.webp";
import Cu from "../../assets/images/cu.webp";
import It from "../../assets/images/it.webp";
import Mu from "../../assets/images/mu.webp";
import S1 from '../../assets/images/service-logo-1.webp'
import S2 from '../../assets/images/service-logo-2.webp'
import S3 from '../../assets/images/service-logo-3.webp'
import S4 from '../../assets/images/service-logo-4.webp'
import S5 from '../../assets/images/service-logo-5.webp'
import S6 from '../../assets/images/service-logo-6.webp'
import S7 from '../../assets/images/service-logo-7.webp'
import IdeaCard from "./IdeaCard";
import Review from "./Review";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const ServiceContent = () => {
  const location = useLocation();
  useEffect(() => {
    const { search } = location;
    const containerId = search.substring(4); // Remove the leading slash
    const element = document.getElementById(containerId);

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "center",
      });
    }
  }, [location]);
  return (
    <div className="mainservicepage">
      <div className="aboutbanner mb-0 serban">
        <h4>Services</h4>
      </div>
      
      <div className="container data">
        <div>
          <h3 className="text-center font-bold" data-aos="fade-down" data-aos-delay="300">
                      Our Services
              </h3>
            <p className="text-center mobilefont" data-aos="fade-down" data-aos-delay="300">
            We are dedicated to transforming your vision in to reality. Partner with us to embark on a journey of innovation, efficiency and digital success. Let's build the future together.
          </p>
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="row data-3" data-aos="fade-down" data-aos-delay="300">
                <div className="col-lg-4 col-md-4 border-end">
                  <div className="datacard">
                    <div className="icon">
                      <i class="fa-solid fa-user"></i>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <h5 className="fw-bold">90+</h5>
                      <span>Clients</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 border-end">
                  <div className="datacard">
                    <div className="icon">
                      <i class="fa-solid fa-location-dot"></i>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <h5 className="fw-bold">30+</h5>
                      <span>Countries</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 border-end">
                  <div className="datacard">
                    <div className="icon">
                      <i class="fa-solid fa-equals"></i>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <h5 className="fw-bold">50+</h5>
                      <span>Projects</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="headline">
          {/* <h3 className="text-center font-bold">Our Services</h3> */}
          {/* <p className='text-center'>Enim egestas at massa senectus justo ut. Hac est.</p> */}
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-5 mb-3" data-aos="fade-down" data-aos-delay="300">
            <div className="serviceCard1">
              <div className="icon">
                <img src={It} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">IT Security & Compliance</h4>
              <p className="mobilefont serviceht">Need data security & compliance?</p>
              <div className="buttons">
                <Link to="/Contactus">
                  <button className="con-btn">Connect Now</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-5 mb-3" data-aos="fade-down" data-aos-delay="300">
            <div className="serviceCard1">
              <div className="icon">
                <img src={Mu} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">MuleSoft support</h4>
              <p className="mobilefont serviceht">
                Our managed and consulting service model helps fill in the
                knowledge gap in IT teams.
              </p>
              <div className="buttons">
                <Link to="/Contactus">
                  <button className="con-btn">Connect Now</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-5 mb-3" data-aos="fade-down" data-aos-delay="300">
            <div className="serviceCard1">
              <div className="icon">
                <img src={Cl} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">Cloud Migration</h4>
              <p className="mobilefont serviceht2">
                Our Cloud migration service helps clients to reduce IT costs and
                improving performance with security and convenience advantage
                too.
              </p>
              <div className="buttons">
                <Link to="/Contactus">
                  <button className="con-btn">Connect Now</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-5 mb-3" data-aos="fade-down" data-aos-delay="300">
            <div className="serviceCard1">
              <div className="icon">
                <img src={Cu} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">Custom Software Development</h4>
              <p className="mobilefont serviceht2">At Optune Technologies, we specialize 
              in crafting bespoke software solutions that align seamlessly with 
              your business objectives. Our experienced team of developers leverages the latest technologies
              to deliver scalable, secure and high-performance applications that give you a competitive edge.
              </p>
              <div className="buttons">
                <Link to="/Contactus">
                  <button className="con-btn">Connect Now</button>
                </Link>
                </div>
                </div>
          </div>
        </div>
      </div>
      {/* <div className="container">
        <div className="headline" data-aos="fade-down" data-aos-delay="300">
          <h3 className="text-center font-bold">
            Why Choose Optune for your Big ideas
          </h3>
          <p className="text-center mobilefont">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suscipit
            tristique volutp
          </p>
        </div>
        <div className="row">
          <div className="col-md-6" data-aos="fade-down" data-aos-delay="300">
            <IdeaCard content="Real-Life Human Beings" />
          </div>
          <div className="col-md-6" data-aos="fade-down" data-aos-delay="300">
            <IdeaCard content="Full Featured" />
          </div>
          <div className="col-md-6" data-aos="fade-down" data-aos-delay="300">
            <IdeaCard content="100% Guarantee" />
          </div>
          <div className="col-md-6" data-aos="fade-down" data-aos-delay="300">
            <IdeaCard content="Commitment to Security" />
          </div>
          <div className="col-md-6" data-aos="fade-down" data-aos-delay="300">
            <IdeaCard content="Super Easy to Use" />
          </div>
          <div className="col-md-6" data-aos="fade-down" data-aos-delay="300">
            <IdeaCard content="24/7 Support" />
          </div>
        </div>
      </div> */}
      <div className="sidecontent">
        <div className="sidecontainer" id="Soc2Type2Audit">
          <div className="container-fluid">
            <div className="row justify-content-between">
              <div className="col-lg-6 my-auto" data-aos="fade-down" data-aos-delay="300">
                <div className="servicecontent">
                  <div className="">
                    <div className="my-4" >
                      <img className="icon-service" src={S1} />
                    </div>
                    <h3 className="font-bold mb-4">Soc2 Type 2 Audit</h3>
                    <p className="mobilefont">
                      SOC 2 controls enable information security controls at a
                      service organization and the audit reports are certified
                      by a licensed CPA firm. All these controls are pertaining
                      to 5 Trust Services Categories: Security, Availability,
                      Processing Integrity, Confidentiality and Privacy. These
                      controls give your customers the confidence to trust your
                      company’s security posture to store and process their
                      sensitive customer and financial information. Soc 2
                      reports are an effective tool to communicate that you have
                      a mature information security program in place
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 my-auto " data-aos="fade-down" data-aos-delay="300">
                <div className="serviceimg">
                  <img src={ServicesImg1} className="h-100 " />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sidecontainer" id="VAPT">
          <div className="container-fluid">
            <div className="row justify-content-between">
              <div className="col-lg-6 my-auto order-lg-2">
                <div className="servicecontent2" data-aos="fade-down" data-aos-delay="300">
                  <div className="">
                    <div className="my-4">
                      <img className="icon-service" src={S2} />
                    </div>
                    <h3 className="font-bold mb-4">VAPT</h3>
                    <p className="mobilefont">
                      Vulnerability Assessment and Penetration Testing (VAPT)
                      services supports in assessing the current status of the
                      security, identifying exact flaws and advising a remedial
                      action plan to safeguard the system. VAPT testing assesses
                      your IT systems and security measures and test for
                      vulnerabilities against the existential, potential
                      external and internal threats. A multi dimensional
                      approach of automated and manual tests put the IT systems
                      through various simulated scenarios that potential hackers
                      may exploit to gain access to your information. The
                      findings will be presented as a detailed risk assessment
                      report and recommending required actions to mitigate the
                      risk. Addressing these security flaws, the assurance is of
                      the best possible protection. Reassessment can be
                      performed to ensure closure of the identified
                      vulnerabilities.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 my-auto order-lg-1" data-aos="fade-down" data-aos-delay="300">
                <div className="serviceimg2">
                  <img src={ServicesImg2} className="h-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sidecontainer" id="ManagedServices">
          <div className="container-fluid">
            <div className="row justify-content-between">
              <div className="col-lg-6 my-auto" data-aos="fade-down" data-aos-delay="300">
                <div className="servicecontent">
                  <div className="">
                    <div className="my-4">
                      <img className="icon-service" src={S3} />
                    </div>
                    <h3 className="font-bold mb-4">Managed Services</h3>
                    <p className="mobilefont">
                    A large support IT staff bridges the chasm between solution acceleration and delayed deployment. 
                    We support our customers pertaining to data analytics, database administration, application development,
                    platform integrations and troubleshooting. There is a lot to be concerned about.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 my-auto " data-aos="fade-down" data-aos-delay="300">
                <div className="serviceimg">
                  <img src={ServicesImg3} className="h-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sidecontainer" id="ConsultingServices">
          <div className="container-fluid">
            <div className="row justify-content-between">
              <div className="col-lg-6 my-auto order-lg-2" data-aos="fade-down" data-aos-delay="300">
                <div className="servicecontent2">
                  <div className="">
                    <div className="my-4">
                      <img className="icon-service" src={S4} />
                    </div>
                    <h3 className="font-bold mb-4">Consulting Services</h3>
                    <p className="mobilefont">
                      Trust us to explore and understand current baseline of
                      your Business Architecture and Technology Architecture.
                      Assess tactical and strategic business drivers to target
                      and objectively articulate the road map across the
                      enterprise, enabling adoption. Evangelize and work with
                      customers on iterative phases for application
                      modernization. Factoring in, Design and Development to
                      build composable assets. Ready automation of built assets
                      for deft deployment and release cycles.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 my-auto order-lg-1" data-aos="fade-down" data-aos-delay="300">
                <div className="serviceimg2">
                  <img src={ServicesImg4} className="h-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sidecontainer" id="Kubernetesmanagement">
          <div className="container-fluid">
            <div className="row justify-content-between">
              <div className="col-lg-6 my-auto" data-aos="fade-down" data-aos-delay="300">
                <div className="servicecontent">
                  <div className="">
                    <div className="my-4">
                      <img className="icon-service" src={S5} />
                    </div>
                    <h3 className="font-bold mb-4">Kubernetes management</h3>
                    <p className="mobilefont">
                      Kubernetes, is an open-source container orchestration
                      platform that automates the deployment, scaling and
                      management of containerized applications. Our Kubernetes
                      management service provides and sustains a robust,
                      flexible and pliable infrastructure for deployment and
                      management of distributed, microservices-based
                      applications at scale. Efficient Kubernetes management is
                      crucial for organizations looking to leverage container
                      orchestration for their applications in a dynamic and
                      rapidly evolving IT landscape.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 my-auto " data-aos="fade-down" data-aos-delay="300">
                <div className="serviceimg">
                  <img src={ServicesImg5} className="h-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sidecontainer" id="ObjectStorage">
          <div className="container-fluid">
            <div className="row justify-content-between">
              <div className="col-lg-6 my-auto order-lg-2" data-aos="fade-down" data-aos-delay="300">
                <div className="servicecontent2">
                  <div className="">
                    <div className="my-4">
                      <img className="icon-service" src={S6} />
                    </div>
                    <h3 className="font-bold mb-4">Object Storage</h3>
                    <p className="mobilefont">
                      Our expertise in data storage architecture helps manage
                      data as objects rather than traditional file systems or
                      block storage. This storage approach is well-suited for
                      managing large volumes of unstructured data and is widely
                      used for scalable and distributed storage solutions. Its
                      design principles align well with the requirements of
                      modern applications and make it a preferred choice for
                      organizations dealing with diverse and growing data sets.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 my-auto order-lg-1" data-aos="fade-down" data-aos-delay="300">
                <div className="serviceimg2">
                  <img src={ServicesImg6} className="h-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sidecontainer" id="GlobalContentDelivery">
          <div className="container-fluid">
            <div className="row justify-content-between">
              <div className="col-lg-6 my-auto" data-aos="fade-down" data-aos-delay="300">
                <div className="servicecontent">
                  <div className="">
                    <div className="my-4">
                      <img className="icon-service" src={S7} />
                    </div>
                    <h3 className="font-bold mb-4">Global content delivery</h3>
                    <p className="mobilefont">
                      Global content delivery in cloud migration involves
                      leveraging cloud-based infrastructure, CDNs and advanced
                      technologies to ensure efficient, reliable and
                      high-performance delivery of digital content to users
                      worldwide. This approach is designed to optimize the
                      performance, availability and reliability of content
                      delivery for users located in different geographic
                      regions. Facilitating, the global reach of modern
                      applications and services also by enhancing and enriching
                      user satisfaction enabling businesses to operate on a
                      global scale.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 my-auto" data-aos="fade-down" data-aos-delay="300">
                <div className="serviceimg">
                  <img src={ServicesImg7} className="h-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      {/* <div className="container">
        <div className="headline" data-aos="fade-down" data-aos-delay="300">
          <h3 className="text-center font-bold">Customer says about Optune</h3>
          <p className="text-center">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
            purus sit
          </p>
        </div>
        <div className="row" >
          <div className="col-lg-12" data-aos="fade-down" data-aos-delay="300">
            <div className="review">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="reviewcard px-2">
                      <div>
                        <i class="fa-solid fa-square"></i>
                      </div>
                      <div className="mt-2">
                        <h5 className="fw-bold">Steve Rogers</h5>
                        <p>Team Leader</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <h5 className="fw-bold mobilefont">Fasted and Trusted!!</h5>
                    <div className="rating">
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12" data-aos="fade-down" data-aos-delay="300">
            <Review ctmr="Sam Wilson" />
          </div>
          <div className="col-lg-12" data-aos="fade-down" data-aos-delay="300">
            <Review ctmr="John Wick" />
          </div>
        </div>
      </div> */}
      {/* <div className="container" data-aos="fade-down" data-aos-delay="300">
        <div className="headlinel">
          <h3 className="text-center font-realbold text-uppercase">
            You need to know more?! lET’S talk now!
          </h3>
          <p className="text-center mobilefont">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
          </p>
          <div className="d-flex justify-content-center">
            <Link to="/Contactus">
              <button className="con-btn fw-bold">GET IN TOUCH</button>
            </Link>
          </div>
        </div>
      </div> */}
     
      
     <div className="topcontent" id="servicepage">
        <div className="container">
          <div className="row">
          <div className="col-lg-6">
              <div className="sr-image">
              <img className="left-radius" src={People} /> 
              </div> 
            </div> 
            <div className="col-lg-6 my-auto">
              <div className="sr-content">
                <h2 className="font-bold">
                  Food Delivery and Aggregation as a Service
                </h2>
                <p className="mobilefont">
                  Our food delivery platform connects users with home chefs and
                  local amateur cooks. Customers can browse through a wide range
                  of home chefs and cooks listed in the app to place orders and
                  have their favorite dishes delivered to their doorstep. The
                  app leverages data analytics to understand customer
                  preferences, behavior and trends. This data-driven approach
                  enables personalized recommendations, targeted marketing and
                  the continuous improvement of the overall user experience. Our
                  business model revolves around leveraging technology,
                  strategic partnerships and a customer-centric approach to
                  provide on-demand food delivery services.
                 </p> 
                {/* <button className="contact-btn">
                  Learn More{"  "} w-100 h-auto
                  <i class="fa-solid fa-arrow-right"></i>
                    </button> */}
              </div>
           </div>
            
          </div> 
        </div> 
        </div> 

      <br></br>

    </div>




  );
};

export default ServiceContent;
