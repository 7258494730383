import React from 'react'
import { Navbar } from "../Components/Navbar/Navbar";
import { Footer } from "../Components/Footer/Footer";
import ServiceContent from '../Components/Services/ServiceContent';

const Services = () => {
    return (
        <div>
            <Navbar seract="active" />
            <ServiceContent />
            <Footer />
        </div>
    )
}

export default Services