import React from 'react'
import profile from '../../assets/Cardimages/profile.webp'

const Customervoice1 = () => {
    return (
        <div className='comment-1'>
            {/* <div className='quote'>
                <i class="fa-solid fa-quote-right"></i>
               </div> */}
            <div className='customervoice'>
                <div>
                    <p>“The seamless communication and collaborative approach made the entire process enjoyable and stress-free. Optune Technologies not only understood our unique requirements but also provided innovative solutions that significantly enhanced the efficiency of our operations. Their attention to detail and dedication to delivering high-quality software solutions set them apart in the industry.”</p>
                </div>
                <div>
                    <div className='profile'>
                        <div>
                           {/* <img src={profile} /> */}
                        </div>
                        <div>
                            <h6 className='font-bold'>Hari Krishnan</h6>
                            <p>Director, Softbridge Solutions</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default Customervoice1