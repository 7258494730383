import React from 'react'
import { Navbar } from "../Components/Navbar/Navbar";
import { Footer } from "../Components/Footer/Footer";
import AboutBanner from '../Components/About/AboutBanner';

const About = () => {
    return (
        <div style={{overflowX:'hidden'}}>
            <Navbar />
            <AboutBanner />
            <Footer />
        </div>
    )
}

export default About
