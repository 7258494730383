import { useEffect } from 'react';
import Hand from '../../assets/images/hand.webp'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import P1 from '../../assets/images/p1.webp'
import P2 from '../../assets/images/p2.webp'
import P3 from '../../assets/images/p3.webp'
import P4 from '../../assets/images/p4.webp'
import P5 from '../../assets/images/p5.webp'
import P6 from '../../assets/images/p6.webp'
import S1 from '../../assets/images/s1.webp'
import S2 from '../../assets/images/s2.webp'
import S3 from '../../assets/images/s3.webp'
import S4 from '../../assets/images/s4.webp'
import S6 from '../../assets/images/s6.jpeg'
import F1 from '../../assets/images/f1.webp'
import F2 from '../../assets/images/f2.webp'
import F3 from '../../assets/images/f3.webp'
import U2 from '../../assets/images/u2.webp'
import U3 from '../../assets/images/u3.webp'
import U4 from '../../assets/images/u4.webp'
import U5 from '../../assets/images/u5.webp'
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const ProductBanner = () => {
    const prtservice = [
        {
            image: P1,
            heading: "Security and privacy",
            content: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
        },
        {
            image: P2,
            heading: "Built-in AI features",
            content: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
        },
        {
            image: P3,
            heading: "Collaborate with others",
            content: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
        },
        {
            image: P4,
            heading: "Real time sync",
            content: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
        },
        {
            image: P5,
            heading: "Built-in AI features",
            content: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
        },
        {
            image: P6,
            heading: "Easy notes organization",
            content: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
        }
    ]

    const Features = [
        {
            image: F1,
            heading: "Advanced 256-bit encryption",
            content: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
        },
        {
            image: F2,
            heading: "Simple collaboration tools",
            content: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
        },
        {
            image: F3,
            heading: "Customizable AI features",
            content: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
        }
    ]
    const location = useLocation();
    useEffect(() => {
        const { search } = location;
        const containerId = search.substring(4); // Remove the leading slash
        const element = document.getElementById(containerId);

        if (element) {
            element.scrollIntoView({
                behavior: "smooth",
                inline: "center",
                block: "center",
            });
        }
    }, [location]);
    return (
        <div>
            <div className=' aboutbanner abtban' >
                <h4 className='font-realbold' data-aos="fade-up" data-aos-delay="300">Products</h4>
            </div>
            {/* <div className='' >
                <div className='container' >
                    <div className='row gx-md-5 mb-5 justify-content-center'>
                        <div className='col-lg-6 col-12 p-3 order-lg-1' data-aos="fade-up" data-aos-delay="300">
                            <div className='prt-cnt' id='productpager'>
                                <h2 className='font-bold' >Grow your Sales and Services</h2>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </p>
                                <div className='prt-btn'>
                                    <Link to="/" onClick={() => window.scrollTo(0, 0)} >
                                        <button className='prt-btn-1'>Get Started</button>
                                    </Link>
                                    <Link to="/Contactus" onClick={() => window.scrollTo(0, 0)} >
                                        <button className='prt-btn-2'>  Contact Us</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-12 order-lg-2' data-aos="fade-up" data-aos-delay="300">
                            <img src={Hand} alt="image" />
                        </div>
                    </div>
                </div>
            </div> */}
            <div>
                <div className="prt-csl">
                    <div className="container">
                        <div className="row">
                            <OwlCarousel
                                className="owl-theme"
                                loop
                                autoplay={true}
                                items="1"
                                dots={false}
                                smartSpeed={2000}
                                nav={false}
                                margin={20}
                                center={true}
                                autoplayTimeout={5000}
                                responsive={{
                                    360: {
                                        items: "3",
                                    },
                                    414: {
                                        items: "3",
                                    },
                                    670: {
                                        items: "3",
                                    },
                                    992: {
                                        items: "3",
                                    },
                                    1200: {
                                        items: "5",
                                    },
                                }}
                            >
                                {/*<div className="">
                                    <img src={U2} alt="logo" className='client-logo' />
                            </div >
                                <div className="">
                                    <img src={U3} alt="logo" className='client-logo' />
                            </div>
                                <div className="">
                                    <img src={U4} alt="logo" className='client-logo' />
                                </div>
                                <div className="">
                                    <img src={U5} alt="logo" className='client-logo' />
                                </div>*/}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='pt-4'>
                <div className='container'>
                    <h2 className='font-realbold text-center '>Get the best out of your company<br></br> with our service</h2>
                    <div className='row justify-content-center' data-aos="fade-up" data-aos-delay="300">
                        <div className='col-lg-11'>
                            <div className='row'>
                                {prtservice.map((item, index) => {
                                    return (
                                        <div className='col-lg-4 col-6' key={index}>
                                            <div className='prt-servicecard'>
                                                <div className='prt-service-card'>
                                                    <img src={item.image} alt="image" className='my-2' />
                                                    <h4 className='my-2 font-bold'>{item.heading}</h4>
                                                    <p className='mt-4 '>{item.content}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='container' id='productfeatures'>
                <div className='row align-items-center my-5'>
                    <div className='order-md-1 col-md-7' data-aos="fade-up" data-aos-delay="300">
                        <div className='content-head'>
                            <h6 className='font-bold text-uppercase lin'>IT SECURITY & COMPLIANCE</h6>
                        </div>
                        <h2 className='font-realbold text-capitalize'>
                            {/* Features */}
                            Secure Your Business
                        </h2>
                        <p>While selecting IT security compliance products, organizations should consider their specific compliance requirements, the nature of their operations and the types of data they handle. It's also important to regularly update and test these tools to ensure they remain effective against evolving security threats. The key aspects of our products are Vulnerability Assessment Tools, Security Information and Event Management (SIEM) Systems, Identity and Access Management (IAM) Solutions, Encryption Solutions, Security Policy Management, Compliance Management Platforms, Data Loss Prevention (DLP) Products, Audit and Reporting Tools, Endpoint Security Solutions and Firewalls and Intrusion Prevention Systems (IPS).</p>
                    </div>
                    <div className='order-md-2 col-md-5' data-aos="fade-up" data-aos-delay="300">
                        {/*<img src={S1} />*/}
                          <img src={S6} />
                        </div>
                </div>
            </div>
            {/* <div className='container'>
                <div className='row align-items-center my-5'>
                    <div className='order-md-2 col-md-7' data-aos="fade-up" data-aos-delay="300">
                        <div className='content-head'>
                            <h6 className='font-bold text-uppercase lin'>TIME TRACKER</h6>
                        </div>
                        <h2 className='font-realbold text-capitalize'>Track Your Project Performance</h2>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren..</p>
                    </div>
                    <div className='order-md-1 col-md-5' data-aos="fade-up" data-aos-delay="300">
                        <img src={S2} />
                    </div>
                </div>
            </div>
            <div className='container' id='productfeatures'>
                <div className='row align-items-center my-5'>
                    <div className='order-md-1 col-md-7' data-aos="fade-up" data-aos-delay="300">
                        <div className='content-head'>
                            <h6 className='font-bold text-uppercase lin'>LEAD GENERATION</h6>
                        </div>
                        <h2 className='font-realbold text-capitalize'>More Leads That Convert</h2>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren.</p>
                    </div>
                    <div className='order-md-2 col-md-5' data-aos="fade-up" data-aos-delay="300">
                        <img src={S3} />
                    </div>
                </div>
            </div>
            <div className='container' id='productfeatures'>
                <div className='row align-items-center my-5'>
                    <div className='order-md-2 col-md-7' data-aos="fade-up" data-aos-delay="300">
                        <div className='content-head'>
                            <h6 className='font-bold text-uppercase lin'>REMOTE TEAMS</h6>
                        </div>
                        <h2 className='font-realbold text-capitalize'>Real-Time Collaboration</h2>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren.</p>
                    </div>
                    <div className='order-md-1 col-md-5' data-aos="fade-up" data-aos-delay="300">
                        <img src={S4} />
                    </div>
                </div>
            </div>
            <div className='container'>
                <h2 className='text-center font-bold mt-5 mb-2' data-aos="fade-up" data-aos-delay="300">Features</h2>
                <div className='wrapper'>
                    <div className='row justify-content-center' data-aos="fade-up" data-aos-delay="300">
                        <div className='col-lg-12'>
                            <div className='row '>
                                {Features.map((item, index) => {
                                    return (
                                        <div className={`col-lg-4 px-5 ${index < Features.length - 1 ? "lean" : ""}`}>
                                            <img src={item.image} alt="image" className='my-2' />
                                            <h4 className='my-2 font-bold'>{item.heading}</h4>
                                            <p className='my-4'>{item.content}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='container'>
                <div className='row justify-content-center mb-5' data-aos="fade-down" data-aos-delay="300">
                    <div className='col-lg-8 text-center'>
                        <h2 className='font-bold'>Are you ready to grow your<br></br> business with us?</h2>
                        {/* <p className='my-5'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy.</p> */}
                        <Link to="/Contactus" onClick={() => window.scrollTo(0, 0)} >
                            <button className='prt-btn-1'>Get in Touch</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ProductBanner