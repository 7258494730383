import React from 'react'
import profile from '../../assets/Cardimages/profile.webp'

const Customervoice = () => {
    return (
        <div className='comment-1'>
            {/* <div className='quote'>
                <i class="fa-solid fa-quote-right"></i>
               </div> */}
            <div className='customervoice'>
                <div className='chefrover'>
                    <p>“I am delighted to express my utmost satisfaction with Optune Technologies, a remarkable software services company that has truly exceeded my expectations. From the initial consultation to the final delivery, their team demonstrated unparalleled professionalism, expertise and a commitment to excellence.”</p>
                </div>
                <div>
                    <div className='profile'>
                        <div>
                           {/* <img src={profile} /> */}
                        </div>
                        
                        <div>
                            <h6 className='font-bold'>Ravindranath Shankar</h6>
                            <p>Director, Chefrover.com</p>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default Customervoice
