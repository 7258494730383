import React, { useState } from "react";
import Video from "../../assets/images/optune_video_7.mp4";
import { HomeContent } from "./HomeContent";
import { HashLink } from 'react-router-hash-link';

export const HomeBanner = () => {
  const [loading, setLoading] = useState(true)
  const loadHandler = () => {
    setLoading(false)
  }
  return (
    <>
      <div class="bg-video-wrap2 mt-5">
        {loading && <p>Loading...</p>}
        <video autoPlay loop muted onLoadedData={loadHandler}>
          <source src={Video} type="video/mp4" />
        </video>
        {loading ? (
          <p>{""}</p>
        ) : (
          <div className="home-video-content">
            <div>
              <div className="row justify-content-center">
                <div className="col-lg-8" data-aos="fade-up" data-aos-delay="500">
                  <h1 className="banner-heading font-exbold">
                    “If <span className="blue-heading font-exbold">IT</span> is Everything,
                    Let’s Look at the <span className="blue-heading font-exbold">Future</span>
                    ”
                  </h1>
                  <div className="text-center">
                    <HashLink to="/#getstarted">
                      <button
                        type="button"
                        className="btn mt-5 btn-primary contact-btn"
                      >
                        Get Started <i class="fa-solid fa-arrow-right"></i>
                      </button>
                    </HashLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        <HomeContent />
      </div>
    </>
  );
};
