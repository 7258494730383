import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React from 'react'
import Kudos from '../../assets/images/Kudos.webp'
import U1 from '../../assets/images/u1.webp'
import U2 from '../../assets/images/u2.webp'
import U3 from '../../assets/images/u3.webp'
import U4 from '../../assets/images/u4.webp'
import U5 from '../../assets/images/u5.webp'
import U6 from '../../assets/images/U6.png'
import U7 from '../../assets/images/U7.png'
import U8 from '../../assets/images/U8.png'     
import U9 from '../../assets/images/U9.png'

const Carousal = () => {
    return (

        <div >
             <div className="container my-5" >
                <div data-aos="fade-up" data-aos-delay="300">
                    <div className='row' >
                        <div className='col-lg-6' >
                            <div className='content-head'>
                                 <h5 className='hr-lines font-bold'>Trusted clients</h5>
                            </div>
                        </div>
                    </div>
                    <h1 className="font-bold">Our Clients</h1>
                   {/* <p className='hm-pr-w2'>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text
                        </p> */}
                </div>
            </div> 
            <div className=" carousal1">
                <div className="container">
                    <div className="row homecsl">
                        <OwlCarousel
                            className="owl-theme"
                            loop
                            autoplay={true}
                            items="1"
                            dots={false}
                            smartSpeed={2000}
                            nav={true}
                            margin={20}
                            center={true}
                            autoplayTimeout={5000}
                            responsive={{
                                360: {
                                    items: "3",
                                },
                                414: {
                                    items: "3",
                                },
                                670: {
                                    items: "3",
                                },
                                992: {
                                    items: "3",
                                },
                                1200: {
                                    items: "5",
                                },
                            }}
                        >
                           {/* <div className="">
                                <img src={U2} alt="logo" className='client-logo' />
                        </div>*/}
                            {/* <div className="">
                                <img src={U3} alt="logo" className='client-logo' />
                    </div> */}
                           <div className="">
                            <img src={U6} alt = "logo" className='client-logo' />
                           </div>
                           <div className="">
                            <img src={U7} alt = "logo" className='client-logo' />
                           </div>
                           <div className="">
                            <img src={U8} alt = "logo" className='client-logo' />
                           </div>
                           <div className="">
                            <img src={U9} alt = "logo" className='client-logo' />
                           </div>
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Carousal